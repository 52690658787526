
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/Schedule.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/Registration.css'
import '../components/Styles/contact.css'
import '../components/Styles/Landing.css'
import FirebaseFirestore from './FirebaseFirestore'
import {Helmet}from"react-helmet";

import m3 from '../images/Rectangle 7 (1)-min.png';
import p1 from '../images/pp1-min.png';
import p2 from '../images/pp2-min.png';
import p3 from '../images/pp3-min.png';
import p4 from '../images/pp4-min.png';
import pa1 from '../images/pa1-min.png';
import pa2 from '../images/pa2-min.png';
import pa3 from '../images/pa3-min.png';
import pa4 from '../images/pa4-min.png';
import re from '../images/re.png';
import gg from '../images/gg.png';
import re2 from '../images/book_mockup-removebg-preview 1.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FirebaseFirestore2 from './FirebaseFirestore2'


 const Landingpage =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Sponsors Spotlight at WealthPath Conference - Top Industry Sponsors</title>
      <meta name="description" content="Discover leading industry sponsors at the WealthPath Conference. Join us to connect with top-tier financial experts and innovators!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
      <section className='header_top'>
      <div className='headertp'>
        <p style={{paddingTop:"11%"}}>Master Wealth Building, Investing, and Financial Freedom</p>
        <br />
        <h1 >Wealth Path Conference <br /> 2025</h1>
        <br />
        <p>Date: May 17, 2025</p>
        <br />
        <p>Location: Wyndham Houston</p>
        <br />
      </div>
      </section>
     <br /><br /><br />



<section>
<div className='lansec1'>

  <div className='lansec1_row'>
  <div className='lansec1_col'>
    <h2>Learn from top financial experts how to invest wisely, grow wealth, and secure your financial future.</h2>
<br />
  <button>Get My Ticket Now</button>
  </div>
  <br />
  <div className='lansec1_col'>
    <div className='lan_bx1'>
      <p>Conference Ticket</p>
      <hr style={{marginLeft:"0%"}} />
      <h3>Wealth Path Conference 2025</h3>
      <br />
      <p style={{textAlign:"center"}}>Date: May 17, 2025</p>
      <br />
      <p style={{textAlign:"center"}}>Location: Wyndham Houston</p>
    </div>
  </div>
  </div>


</div>
</section>

<br />
<br /><br />
<section>
  <div className='lansec1a'>
  <h2>Why Attend?</h2>
  <br /><br />
  <div className='lansec1_row2'>
  <div className='lansec1_col2'>
    <p style={{textAlign:"left"}}>&#x2022; Proven wealth-building strategies from top financial minds.</p>
    <p style={{textAlign:"left"}}>&#x2022; Actionable investment insights in stocks, real estate, and business.</p>
    <p style={{textAlign:"left"}}>&#x2022; Smart financial management techniques to protect and grow your assets.</p>
    <p style={{textAlign:"left"}}>&#x2022; Valuable networking with serious investors, business owners, and professionals.</p>
    <p style={{textAlign:"left"}}>&#x2022; Exclusive gift: Every attendee receives a free copy of <span>Deep Freakin’ Value</span>  by <span>Mohammad Ali.</span> </p>
    <p style={{textAlign:"left"}}>&#x2022; Take control of your financial future. Don’t wait.</p>
    <br />
    <button>Reserve My Spot</button>
  </div>
  <br />
  <div className='lansec1_col2'>
    <img src={m3} alt="" />
  </div>
  </div>
  </div>
  
</section>
<br /><br />
<br />
<section>
  <div className='lansec1a'>
<h2>Meet the Speakers</h2>
<p>Gain insights from industry-leading experts:</p>
<br /><br />
<div className='row_spe'>
  <div className='col_sp'>
    <img src={p1} alt="" />
    <br /><br />
    <h3 style={{fontFamily:"Manrope",fontSize:"24px",color:"black",fontWeight:"700",textAlign:"center"}}>ALI</h3>
    
    <p style={{fontFamily:"Manrope",fontSize:"20px",lineHeight:"25px",paddingTop:"5px"}}>Fund Manager at 33% Fund, Expert in Value and Shariah-Compliant Investing</p>

  </div>
  <br />
  <div className='col_sp'>
  <img src={p2} alt="" />
  <br /> <br />
    <h3 style={{fontFamily:"Manrope",fontSize:"24px",color:"black",fontWeight:"700",textAlign:"center"}}>Dr. Khaled Hamza</h3>
    <p  style={{fontFamily:"Manrope",fontSize:"20px",lineHeight:"25px",paddingTop:"5px"}}>Wealth Management and Asset Growth Strategist</p>
  </div>
  <br />
  <div className='col_sp'>
  <img src={p3} alt="" />
  <br /><br />
    <h3 style={{fontFamily:"Manrope",fontSize:"24px",color:"black",fontWeight:"700",textAlign:"center"}}>Ala Salous</h3>
    <p  style={{fontFamily:"Manrope",fontSize:"20px",lineHeight:"25px",paddingTop:"5px"}}>Financial Expert and Entrepreneur</p>
  </div>
  <br />
  <div className='col_sp'>
  <img src={p4} alt="" />
  <br /><br />
    <h3 style={{fontFamily:"Manrope",fontSize:"24px",color:"black",fontWeight:"700",textAlign:"center"}}>Feras Moussa</h3>
    <p  style={{fontFamily:"Manrope",fontSize:"20px",lineHeight:"25px",paddingTop:"5px"}}>Investment Strategist and Business Leader</p>
  </div>
</div>
  </div>
</section>
<br /><br /> <br />
<section>
  <div className='lansec1b'>
<h2>What You Will Learn</h2>
<p>Financial security doesn’t happen by chance—it’s built through knowledge and smart strategies. At this conference, you will discover how to:</p>
<br /><br />
<div className='row_spe'>
  <div className='col_sp'>
    <img src={pa1} alt="" />
    <h2>Multiply Your <br /> Wealth</h2>
    <br />
    <p>Learn the proven methods successful investors use.</p>
  </div>
  <div className='col_sp'>
  <img src={pa2} alt="" />

    <h2>Invest in Real <br /> Estate</h2>
    <br />
    <p>Build passive income streams and maximize property investments.</p>
  </div>
  <div className='col_sp'>
  <img src={pa3} alt="" />
    <h2>Master the Stock <br /> Market</h2>
    <br />
    <p>Navigate the best investment opportunities in 2025 and beyond.</p>
  </div>
  <div className='col_sp'>
  <img src={pa4} alt="" />
    <h2>Build and Protect <br /> Your Assets</h2>
    <br />
    <p>Smart tax planning and risk management strategies.
Start making smarter financial decisions today.
</p>
  </div>
</div>
<br />
<button>Claim Your Spot</button>
  </div>


</section>
<br /><br /><br />
<section>


<div className='lansec1c'>

  <h2>Success Stories from Past Attendees</h2>

  <div className='row_3'>
    
    <div className='col__3'>

     <div className='row__3a'>
      <div className='col__3a'>
       <img src={re} alt="" />
      </div>
      <div className='col__3b'>
        <h2>Omar A.</h2>
        <p>Before attending the Wealth Path Conference, I had no clear investment plan. Now, I own multiple properties and my stock portfolio has grown tremendously.</p>
      </div>
     </div>
    </div>
<br />
    <div className='col__3'>
     <div className='row__3a'>
      <div className='col__3a'>
      <img src={re} alt="" />
      </div>
      <div className='col__3b'>
        <h2>Fatima R.</h2>
        <p>The conference gave me clarity on how to manage my money and invest wisely. I finally feel in control of my financial future.</p>
      </div>
     </div>
    </div>
    <br />
    <div className='col__3'>
     <div className='row__3a'>
      <div className='col__3a'>
      <img src={re} alt="" />
      </div>
      <div className='col__3b'>
        <h2>Yusuf M.</h2>
        <p>I met investors who helped me grow my business. The networking alone was worth the ticket price.</p>
      </div>
     </div>
    </div>

  </div>
  </div>


</section>

<br /><br /><br />
<section>
  <div className='lansecdd' >
    <div className='row__4'>
      <div className='col__4a'>
        <h3>Ticket Pricing – Limited Availability</h3>
        <h3>Seats are limited. Prices will increase soon.</h3>
        
        <h2>General Admission – $150</h2>
        <br />
        <button>Get My Ticket Now</button>
        <br /><br />
      </div>
      <div className='col__4b'>
        <p>Includes full conference access and a free copy of Deep Freakin’ Value by Mohammad Ali.</p>
      </div>
      <div className='col__4c'>
        <img src={re2} alt="" />
      </div>
    </div>
  </div>
</section>
<br /><br /><br />
<section>
  <div className='lansec1b'>
    <h2>Frequently Asked Questions</h2>
<br /><br />
    <div className='faq__sec'>
    <br />
  <details>
  <summary data-aos="fade-right">Where is the event located?</summary>
  <div>
  <p> </p>
  </div>
</details>
<br />
<details>
<summary data-aos="fade-right">Can I attend virtually? 
</summary>
  <div>
  <p></p>
  </div></details>
<br />
<details>
<summary data-aos="fade-right">What should I bring? 
</summary>  <div>
<p></p>
</div>
</details>
<br />
<details>
<summary data-aos="fade-right">How do I secure my ticket?</summary>  <div>
<p> </p>
</div>

</details>

  </div>
  </div>
</section>

<br /><br /><br />
<section>
<div className='lansecdd'>
    <div className='row5'>
      <div className='col5a'>
        <p>Reserve Your Spot Before Tickets Sell Out</p>
        <h2>This is your chance to learn from financial experts and take action toward a wealthier future.</h2>
       <button>Book My Seat Now</button>
      </div>
      <div className='col5b'>
        <img src={gg} alt="" />
      </div>
    </div>
      </div>
</section>
<br /><br /><br />
<section>
  <div className='lansecdd' >
    <div className='row__4'>
      <div className='col__4a'>
        <h3>Gain expert insights on investing, financial freedom, and asset protection.</h3>
        
        
        <h2>Meet and network with serious investors and professionals.</h2>
        <br />
        <button>Get My Ticket Now</button>
        <br /><br />
      </div>
      <div className='col__4b'>
        <p>Receive a free copy of Deep Freakin’ Value by Mohammad Ali.</p>
      </div>
      <div className='col__4c'>
        <img src={re2} alt="" />
      </div>
    </div>
  </div>
</section>
<br /><br />
    </div>
  )
}
export default Landingpage;